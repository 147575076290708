/** @jsx jsx */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { EliteData } from "@/data";
import { jsx } from "@emotion/react";
import { useMediaQuery } from 'react-responsive';
import {
  abAgency,
  pr79,
  aprovados,
  content,
} from "../assets/styles/AboutTwo.styles";

import Elite from "../assets/images/LP-teamsix/elite.png";

const AboutTwo = () => {
  const [counter, setCounter] = useState({
    startCounter: false,
  });
  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setCounter({ startCounter: true });
    }
  };
  const isMobile = useMediaQuery({
    query: '(max-width: 850px)'
  })

  return (
    <section css={abAgency}>
      <Container fluid>
        <Row className="title-content">
        {!isMobile &&
          <Col
          className="img-container px-0"
          data-aos="fade-right"
          lg={6}
          md={12}
          sm={12}
        >
          <img src={Elite} alt="Calendario prof no app para celular" />
        </Col>}
          <Col css={content} lg={6} md={12} sm={12}>
            <div>
              <h1>EFICIÊNCIA DE ELITE FOCADA EM CONCURSOS MILITARES</h1>
              <p>
                Os Cursos Preparatórios para Carreira Militar do Team Six
                possuem experiência comprovada e o know-how de aprovação. Nos
                últimos 7 anos, fomos líderes em aprovação no RS, formando
                profissionais que hoje servem ao MIPT, MIT, Universidade de
                Coimbra e Mombukagakusho.
              </p>
              <div css={aprovados}>
                {EliteData.map(({ aprovados, countNumber, curso }, index) => (
                  <div>
                    <h3>
                      <VisibilitySensor
                        onChange={onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <CountUp end={counter.startCounter ? countNumber : 0} />
                      </VisibilitySensor>
                    </h3>
                    <p>
                      {aprovados} <b>{curso}</b>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Col>        
        </Row>
      </Container>
      {isMobile && 
      <Col
      className="img-container px-0"
      data-aos="fade-right"
      lg={6}
      md={12}
      sm={12}
    >
      <img src={Elite} alt="Calendario prof no app para celular" />
    </Col>}
    </section>
  );
};

export default AboutTwo;
