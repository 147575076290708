/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";

import { carreiraMilitarSection } from "../assets/styles/AboutTwo.styles";
import IcoArrow from "../assets/images/LP-teamsix/arrow.svg";

const AboutTwo = () => {
  return (
    <section css={carreiraMilitarSection}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h2>POR QUE INGRESSAR NA CARREIRA MILITAR?</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={5} md={5} sm={12}>
            <p>
              Uma carreira militar exige indivíduos intelectualmente capacitados
              e emocionalmente preparados para enfrentar os rigores da vida
              militar. Sabemos que a carreira militar tem uma das remunerações
              salariais mais elevadas do país, mas o caminho do sucesso exige
              aprimoramento acadêmico e o fortalecimento do caráter e da
              liderança. A carreira militar tem outros benefícios:
            </p>
          </Col>
          <Col lg={1} md={1} sm={1} />
          <Col lg={5} md={4} sm={12}>
            <h3 data-aos="fade-right" data-aos-delay="150">
              <img src={IcoArrow} alt="" /> ESTABILIDADE E SEGURANÇA FINANCEIRA
            </h3>
            <h3 data-aos="fade-right" data-aos-delay="300">
              <img src={IcoArrow} alt="" /> PLANO DE CARREIRA ESTRUTURADO
            </h3>
            <h3 data-aos="fade-right" data-aos-delay="450">
              <img src={IcoArrow} alt="" /> VERSATILIDADE PROFISSIONAL, COM
              TREINAMENTOS DE LIDERANÇA E GESTÃO DE CRISES.
            </h3>
            <h3 data-aos="fade-right" data-aos-delay="600">
              <img src={IcoArrow} alt="" /> APOSENTADORIA E BENEFÍCIOS
              VITALÍCIOS
            </h3>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
