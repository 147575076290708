/** @jsx jsx */
import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";

import {
  teamSix,
  year,
  mapSection,
  mapaDoBrasil,
  next,
  mapaDoBrasilMobile,
  animaMapaMobile,
  desktop,
  mobile
} from "../assets/styles/AboutTwo.styles";
import Map from "../assets/images/LP-teamsix/mapa.png";
import Map2003Mobile from "../assets/images/LP-teamsix/2003mobile.png";
import Year2003 from "../assets/images/LP-teamsix/2003.svg";
import Year2024 from "../assets/images/LP-teamsix/2024.svg";
import Tower from "../assets/images/LP-teamsix/estados/tower.svg";
import ArrowLeft from "../assets/images/LP-teamsix/icons/arrow-left.svg";
import ArrowRight from "../assets/images/LP-teamsix/icons/arrow-right.svg";

import ac from "../assets/images/LP-teamsix/estados/ac.svg";
import al from "../assets/images/LP-teamsix/estados/al.svg";
import am from "../assets/images/LP-teamsix/estados/am.svg";
import ap from "../assets/images/LP-teamsix/estados/ap.svg";
import ba from "../assets/images/LP-teamsix/estados/ba.svg";
import ce from "../assets/images/LP-teamsix/estados/ce.svg";
import es from "../assets/images/LP-teamsix/estados/es.svg";
import go from "../assets/images/LP-teamsix/estados/go.svg";
import mg from "../assets/images/LP-teamsix/estados/mg.svg";
import ms from "../assets/images/LP-teamsix/estados/ms.svg";
import mt from "../assets/images/LP-teamsix/estados/mt.svg";
import pr from "../assets/images/LP-teamsix/estados/pr.svg";
import rj from "../assets/images/LP-teamsix/estados/rj.svg";
import ro from "../assets/images/LP-teamsix/estados/ro.svg";
import rr from "../assets/images/LP-teamsix/estados/rr.svg";
import rs from "../assets/images/LP-teamsix/estados/rs.svg";
import sc from "../assets/images/LP-teamsix/estados/sc.svg";
import se from "../assets/images/LP-teamsix/estados/se.svg";
import sp from "../assets/images/LP-teamsix/estados/sp.svg";
import to from "../assets/images/LP-teamsix/estados/to.svg";
import rn from "../assets/images/LP-teamsix/estados/rn.svg";
import pa from "../assets/images/LP-teamsix/estados/pa.svg";
import ma from "../assets/images/LP-teamsix/estados/ma.svg";
import pi from "../assets/images/LP-teamsix/estados/pi.svg";
import pe from "../assets/images/LP-teamsix/estados/pe.svg";
import pb from "../assets/images/LP-teamsix/estados/pb.svg";
import df from "../assets/images/LP-teamsix/estados/df.svg";

const AboutTwo = () => {
  const [estados, setEstados] = useState([
    { nome: "ac", src: ac, show: false },
    { nome: "al", src: al, show: false },
    { nome: "am", src: am, show: false },
    { nome: "ap", src: ap, show: false },
    { nome: "ba", src: ba, show: false },
    { nome: "ce", src: ce, show: false },
    { nome: "es", src: es, show: false },
    { nome: "go", src: go, show: false },
    { nome: "mg", src: mg, show: false },
    { nome: "ms", src: ms, show: false },
    { nome: "mt", src: mt, show: false },
    { nome: "pr", src: pr, show: false },
    { nome: "rj", src: rj, show: false },
    { nome: "ro", src: ro, show: false },
    { nome: "rr", src: rr, show: false },
    { nome: "rs", src: rs, show: false },
    { nome: "sc", src: sc, show: false },
    { nome: "se", src: se, show: false },
    { nome: "sp", src: sp, show: false },
    { nome: "to", src: to, show: false },
    { nome: "rn", src: rn, show: false },
    { nome: "pa", src: pa, show: false },
    { nome: "ma", src: ma, show: false },
    { nome: "pi", src: pi, show: false },
    { nome: "pe", src: pe, show: false },
    { nome: "pb", src: pb, show: false },
    { nome: "df", src: df, show: false },
  ]);

  const [torres, setTorres] = useState([
    { nome: "t1", src: Tower, show: false },
    { nome: "t2", src: Tower, show: false },
    { nome: "t3", src: Tower, show: false },
    { nome: "t4", src: Tower, show: false },
    { nome: "t6", src: Tower, show: false },
    { nome: "t7", src: Tower, show: false },
    { nome: "t8", src: Tower, show: false },
    { nome: "t9", src: Tower, show: false },
    { nome: "t10", src: Tower, show: false },
    { nome: "t11", src: Tower, show: false },
    { nome: "t12", src: Tower, show: false },
    { nome: "t13", src: Tower, show: false },
    { nome: "t14", src: Tower, show: false },
    { nome: "t15", src: Tower, show: false },
    { nome: "t5", src: Tower, show: false },
    { nome: "t16", src: Tower, show: false },
  ]);

  const [hide2003, setHide2003] = useState(false);

  const mapaRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          showAnimationMobile();
          observer.disconnect(); 
        }
      });
    });

    if (mapaRef.current) {
      observer.observe(mapaRef.current);
    }

    return () => {
      observer.disconnect(); 
    };
  }, []); 

  const showStates = () => {
    let novosEstados = [...estados];
    estados.forEach((estado, index) => {
      setTimeout(() => {
        novosEstados[index] = { ...estado, show: true };
        setEstados([...novosEstados]);
      }, index * 200);
    });
  };

  const showTowers = () => {
    let novasTorres = [...torres];
    torres.forEach((torre, index) => {
      setTimeout(() => {
        novasTorres[index] = { ...torre, show: true };
        setTorres([...novasTorres]);
      }, index * 200);
    });
  };

  const showAnimation = () => {
    showStates();
    setTimeout(() => {
      showTowers();
    }, 2000);
  };

  const showAnimationMobile = () => {
    showStates();
    setTimeout(() => {
      showTowers();
    }, 2000);
  };

  const changeText = () => {
    setHide2003(!hide2003);
  };

  return (
    <section id="quem-somos" css={teamSix}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h2>TEAM SIX</h2>
            <h5>
              LEGADO EM ENSINO
              <br /> DE ALTA PERFORMANCE
            </h5>
          </Col>
        </Row>
        <div css={mapSection}>
          <Row>

              <Col css={desktop} lg={7} md={7} sm={12}>
                <div css={mapaDoBrasil}>
                  {torres.map((torre) => (
                    <div className={torre.nome + " torre"}>
                      <img
                        src={torre.src}
                        className={torre.show ? "up" : "down"}
                      />
                    </div>
                  ))}

                  {estados.map((estado) => (
                    <div className={estado.nome + " estado"}>
                      <img
                        src={estado.src}
                        className={estado.show ? "show" : "hide"}
                      />
                    </div>
                  ))}

                  <img src={Map} alt="" />
                </div>
              </Col>

              <Col css={desktop} lg={4} md={44} sm={12}>
                <div css={year} className={hide2003 && "hide"}>
                  <div className="year2003">
                    <img src={Year2003} />
                    <p>
                      Somos os pioneiros no Rio Grande do Sul quando se fala em
                      ensino de alta performance e preparação para carreiras
                      militares. A trajetória do Team Six começa em 2003, quando
                      Daniel Lavouras começou o legado ELITE, com turmas de
                      pré-vestibular em Porto Alegre.
                    </p>
                  </div>
                  <div className="year2024">
                    <img src={Year2024} />
                    <p>
                      Nosso legado e expertise em aprovação passa a se chamar
                      Team Six Elite Militar, uma revolução no horizonte dos
                      aspirantes a carreiras militares. Com raízes fincadas na
                      honra e na história, o Team Six desdobra o mapa para uma
                      jornada sem precedentes, com a experiência de décadas de
                      metodologia baseada em pesquisas neurocientíficas. 
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => {
                    showAnimation();
                    changeText();
                  }}
                  css={next}
                >
                  {!hide2003 && "trajetória "}
                  <span>
                    {!hide2003 ? (
                      <img src={ArrowRight} />
                    ) : (
                      <img src={ArrowLeft} />
                    )}
                  </span>
                </button>
                {/* <button onClick={() => showStates()}>show</button>
          <button onClick={() => showTowers()}>show</button> */}
              </Col>

              <div css={[mapaDoBrasilMobile, mobile]} ref={mapaRef}>
                <img src={Map2003Mobile} className='mapMobile' alt="" />
                <img src={Year2003} className='mobile2003' />
                    <p>
                      Somos os pioneiros no Rio Grande do Sul quando se fala em
                      ensino de alta performance e preparação para carreiras
                      militares. A trajetória do Team Six começa em 2003, quando
                      Daniel Lavouras começou o legado ELITE, com turmas de
                      pré-vestibular em Porto Alegre.
                    </p>
              </div>

              <Col css={mobile} lg={7} md={7} sm={12}>
                <div css={animaMapaMobile}>
                  {torres.map((torre) => (
                    <div className={torre.nome + " torre"}>
                      <img
                        src={torre.src}
                        className={torre.show ? "up" : "down"}
                      />
                    </div>
                  ))}

                  {estados.map((estado) => (
                    <div className={estado.nome + " estado"}>
                      <img
                        src={estado.src}
                        className={estado.show ? "show" : "hide"}
                      />
                    </div>
                  ))}

                  <img src={Map} className='mapaBase' alt="" />
                </div>
              </Col>

              <div css={[mapaDoBrasilMobile, mobile]} ref={mapaRef}>
                <img className="mobile2024" src={Year2024} />
                    <p>
                      Nosso legado e expertise em aprovação passa a se chamar
                      Team Six Elite Militar, uma revolução no horizonte dos
                      aspirantes a carreiras militares. Com raízes fincadas na
                      honra e na história, o Team Six desdobra o mapa para uma
                      jornada sem precedentes, com a experiência de décadas de
                      metodologia baseada em pesquisas neurocientíficas. 
                    </p>
              </div>

          </Row>
        </div>
      </Container>
    </section>
  );
};

export default AboutTwo;
