/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import {
  abAgency,
  pr79,
  aprovados,
  content,
  icons,
  iconsBg,
  desktop,
  mobile
} from "../assets/styles/PrimeiroPasso.style";

import Material from "../assets/images/LP-teamsix/icons/material-icon.svg";
import Plano from "../assets/images/LP-teamsix/icons/plano-icon.svg";
import Atendimento from "../assets/images/LP-teamsix/icons/atendimento-icon.svg";
import Simulados from "../assets/images/LP-teamsix/icons/simulados-icon.svg";
import Plataformas from "../assets/images/LP-teamsix/icons/plataformas-icon.svg";

const PrimeiroPasso = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
        setScrollPosition(window.scrollY/2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <section css={abAgency}>
          <Col css={mobile} className="img-container px-0" lg={5} md={12} sm={12}>
          <div
            css={iconsBg}
            className="bg-image"
            style={{ backgroundPositionY: scrollPosition }}
          ></div>
        </Col>
      <Container fluid>
        <Row className="title-content">
          <Col css={content} lg={7} md={12} sm={12}>
            <div>
              <h1>DÊ O PRIMEIRO PASSO PARA SUA CARREIRA MILITAR</h1>
              <p>
                Faça parte do Team Six e entre para a elite militar! O
                ecossistema Team Six é uma incubadora de talentos, que forma
                profissionais que hoje trabalham servindo e protegendo nosso
                país com distinção e honra.
              </p>
              <div css={icons}>
                <Row>
                  <Col css={content} lg={6}>
                    <img src={Material} />
                    <span>MATERIAL DIDÁTICO COM METODOLOGIA COMPROVADA</span>
                  </Col>
                  <Col css={content} lg={6}>
                    <img src={Plano} />
                    <span>PLANO DE ESTUDO PERSONALIZADO</span>
                  </Col>
                  <Col css={content} lg={6}>
                    <img src={Atendimento} />
                    <span>ATENDIMENTO PEDAGÓGICO INDIVIDUAL</span>
                  </Col>
                  <Col css={content} lg={6}>
                    <img src={Simulados} />
                    <span>SIMULADOS</span>
                  </Col>
                  <Col css={content} lg={6}>
                    <img src={Plataformas} />
                    <span>PLATAFORMAS DIGITAIS E PRESENCIAIS </span>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>

          <Col css={desktop} className="img-container px-0" lg={5} md={12} sm={12}>
          <div
            css={iconsBg}
            className="bg-image"
            style={{ backgroundPositionY: scrollPosition }}
          ></div>
        </Col>
        </Row>
      </Container>
      
    </section>
  );
};

export default PrimeiroPasso;
