/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'

import { ctaSectionConheca } from '../assets/styles/AboutTwo.styles'
import { commonBtn } from "../assets/styles/layout.styles";

const CtaContent = () => {
  return (
    <Fragment>
      <section css={ ctaSectionConheca }>
        <div>
          <h2>Conheça as oportunidades de concursos militares em todo país!</h2>
          <p>Garanta sua vaga agora!</p>
          <a css={commonBtn} href="https://web.whatsapp.com/send?phone=5551991709380&text=" target="_blank">Fale conosco</a>
        </div>
      </section>
    </Fragment>
  )
}

export default CtaContent
