/** @jsx jsx */
import React from "react";
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { Parallax } from 'react-parallax';
import BgSection2 from "../assets/images/LP-teamsix/bg1.jpg";

import {ctaSectionFacaParte } from '../assets/styles/AboutTwo.styles'
import { commonBtn } from "../assets/styles/layout.styles";

const CtaContent = () => {

  return (
    <Fragment>
      <Parallax bgImage={BgSection2} bgImageAlt="the cat" strength={300}>
      <section css={ctaSectionFacaParte}>
        <div>
          <h2>Faça parte da Elite Militar!</h2>
          <p>Somos referência em aprovação nos principais concursos militares! Turmas: ESA, EsPCEx, AFA, EFOMM, EN, IME e ITA.</p>
          <a css={commonBtn} href="https://web.whatsapp.com/send?phone=5551991709380&text=" target="_blank">FALAR COM O TEAM SIX</a>
        </div>
        <div className="parallax-element"></div>
      </section>
    </Parallax>
    </Fragment>
  )
}

export default CtaContent
