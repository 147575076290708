/** @jsx jsx */
import { jsx } from "@emotion/react";

import { containerCard } from "../assets/styles/CardSwiper.styles";
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import photoTeacher from '../assets/images/LP-images/photo-teacher.png'

function CardSlider() {
  return (
    <div css={containerCard}>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={0}
        loop={false}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          851: {
            spaceBetween: 8,
          },
          510: {
            spaceBetween: 8,
          },
        }}
        className="swiper"
      >
        <SwiperSlide>
          <div className="swiper-slide__container">
            <h5>EXCELÊNCIA INSUPERÁVEL</h5>
            <p>
              Cultivamos uma cultura de excelência intransigente. Cada desafio é
              uma oportunidade para demonstrar nossa superioridade acadêmica e
              moral
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
          <h5>RESILIÊNCIA INDOMÁVEL</h5>
            <p>
            A resiliência é o cerne da formação militar. Fortalecemos no aluno a capacidade de se recuperar rapidamente de adversidades, preparando-o para as provações da vida militar
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
          <h5>DISCIPLINA INABALÁVEL</h5>
            <p>
            A disciplina é um modo de vida.O aluno aprende a importância de uma rotina rigorosa, uma ética de trabalho inabalável e uma dedicação inquebrável aos seus objetivos
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
          <h5>ESTRATÉGIA E TÁTICA PRECISAS</h5>
            <p>
            Cultivamos uma cultura de excelência intransigente. Cada desafio é uma oportunidade para demonstrar nossa superioridade acadêmica e moral
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
          <h5>LIDERANÇA VISIONÁRIA</h5>
            <p>
            Desenvolvemos líderes capazes de inspirar, motivar e guiar outros, imbuídos com a sabedoria e a integridade que definem os grandes comandantes militares.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
          <h5>ADAPTABILIDADE VERSÁTIL</h5>
            <p>
            Preparamos o aluno para ser flexível e adaptável, capaz de prosperar em qualquer ambiente acadêmico ou militar, enfrentando mudanças com confiança.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
          <h5>ESPÍRITO SOLIDÁRIO</h5>
            <p>
            Ensinamos a importância do trabalho em equipe, no qual colaboração e respeito mútuo são essenciais para alcançar objetivos comuns em situações de alta pressão
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
          <h5>INTEGRIDADE E HONRA</h5>
            <p>
            No Team Six a integridade não é negociável. Contagiamos nossos alunos com nosso senso de honra e de compromisso com a verdade e a justiça
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-pagination" />
    </div>
  );
}

export default CardSlider;
