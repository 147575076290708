/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { conhecaOFundadorSection, info } from '../assets/styles/AboutTwo.styles'
import { commonBtn } from "../assets/styles/layout.styles";

import ImgBagual from '../assets/images/LP-teamsix/bagual.png'

const AboutTwo = () => {
  return (
    <section id="fundador" css={ conhecaOFundadorSection }>
      <Container>
        <Row className='title-content'>
          <Col lg={1} md={1} sm={12} />
          <Col
            lg={4}
            md={4}
            sm={12}
          >
            <img src={ImgBagual} alt='Daniel Lavouras (Bagual)' />
          </Col>
          
          <Col 
            lg={7} 
            md={12} 
            sm={12}
          >
            <div css={info}>
            <h2>CONHEÇA O FUNDADOR DO TEAMSIX</h2>
            <p>A mente por trás da alta performance:</p>
            <h3>Daniel Lavouras (Bagual)</h3>
            <p>
              - Fundador do Sistema Elite de Ensino e do Elite POA (atual Team Six).<br />
              - Professor e fundador das principais olimpíadas de conhecimento do Brasil.<br />
              - Engenheiro Aeronáutico formado pelo ITA.
            </p>
            <h4>HÁ UM ENORME LEGADO PARA LEVAR ADIANTE!</h4>
            <a css={commonBtn} href="https://web.whatsapp.com/send?phone=5551991709380&text=" target="_blank">JUNTE-SE À ELITE!</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutTwo
