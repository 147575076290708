/** @jsx jsx */
import { jsx } from '@emotion/react'
import CardSlider from './cardSwiper'
import { Container } from 'react-bootstrap'
import { styles } from '../assets/styles/Testimony.styles'

const Testimony = () => {
  return (
    <section id='parceiros' css={styles}>
      <Container>
        <h2>VISÃO E VALORES PARA MOLDAR <br/> LÍDERES MILITARES</h2>
        <p>Aqui o currículo é orientado para desenvolver não apenas a mente, mas também o caráter, com métodos de ensino que são ao mesmo tempo rigorosos e inovadores. Assim, você não só absorve o conhecimento, mas também desenvolve as habilidades críticas de pensamento, resolução de problemas e liderança.</p>
      </Container>
      <CardSlider />
    </section>
  )
}

export default Testimony
