/** @jsx jsx */
import { css } from "@emotion/react";

export const styles = css`
  padding: 117px 0px 77px 0px;
  height: fit-content;
  background-color: #ffffff;

  @media (max-width: 768px) {
    padding: 56px 0px 0px 0px;
  }

  h2 {
    text-align: center;
    color: #2f382b;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* text-transform: uppercase; */

    @media (max-width: 768px) {
      text-align: center;
      font-size: 38px;
      /* max-width: 320px; */
    }
  }

  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #9A9A9A;
    max-width: 730px;
    text-align: center;
    margin: 0 auto;
    letter-spacing: 0;
  }
`;
