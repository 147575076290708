/** @jsx jsx */
import React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade, Pagination } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import { SliderOneData } from "@/data";
import { jsx } from "@emotion/react";
import ArrowLeft from "../assets/images/LP-teamsix/icons/arrow-left.svg";
import ArrowRight from "../assets/images/LP-teamsix/icons/arrow-right.svg";

import {
  mainSlider,
  sliderBtn,
  shapeImg,
  infoSlider,
  imageSlider,
  desktop,
  mobile
} from "../assets/styles/SliderOne.styles";
import { commonBtn, secTitle, subtitle } from "../assets/styles/layout.styles";

SwiperCore.use([Autoplay, Navigation, EffectFade, Pagination]);
const SliderOne = () => {

  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    // effect: "fade",
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    pagination: {
      el: "#carousel-pagination",
      type: "bullets",
      clickable: true,
    },
  };
  return (
    <section css={mainSlider}>
      <Swiper {...mainSlideOptions}>
        {SliderOneData.map(
          ({ image, imageMobile, subTitle, title, highlight, button }, index) => (
            <SwiperSlide key={index}>
              {/* <div
                className="image-layer"
                style={{ backgroundImage: `url(${image})` }}
              ></div> */}
              <img css={mobile} className="image-layer" src={imageMobile} />
              <img css={desktop} className="image-layer" src={image} />
              
              <Container>
                <Row>
                  <Col lg={6}>
                    <div
                      data-aos-id={index}
                      data-aos={"fade-in"}
                      data-aos-delay={"300"}
                      css={infoSlider}
                    >
                      <h3>
                        {title}
                        <span>{highlight}</span>
                      </h3>
                      <p>{subTitle}</p>
                      <Link target="_blank" to={button.url} css={[commonBtn, sliderBtn]}>
                        <span>{button.label}</span>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div
                      data-aos={"fade"}
                      data-aos-delay={"800"}
                      css={imageSlider}
                    >
                      {/* <img src={image} alt="" /> */}
                    </div>
                  </Col>
                </Row>
              </Container>
            </SwiperSlide>
          )
        )}
        <div className="swiper-button-prev" id="main-slider-prev">
          <img src={ArrowLeft} />
        </div>
        <div className="swiper-button-next" id="main-slider-next">
          <img src={ArrowRight} />
        </div>
        <div className="swiper-pagination" id="carousel-pagination"></div>
      </Swiper>
    </section>
  );
};

export default SliderOne;
