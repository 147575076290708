/** @jsx jsx */
import { css } from "@emotion/react";
import BgSection2 from "../images/LP-teamsix/bg-section-2.svg";
import BgSection3 from "../images/LP-teamsix/bg-section-3.svg";
import BgSection6 from "../images/LP-teamsix/bg-section-6.svg";
import BgSection9 from "../images/LP-teamsix/bg-section-9.svg";
import BgSection11 from "../images/LP-teamsix/bg-section-11.svg";
import BgIcons from "../images/LP-teamsix/icons-bg.svg";

export const abAgency = css`
  /* background: url(${BgSection2}) no-repeat center center / cover; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 80px 0; */
  @media (max-width: 500px) {
    /* margin-top: 32px; */
    padding-bottom: 48px;
  }

  img {
    width: 90%;
  }

  .app-buttons {
    margin-top: 61px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 500px) {
      margin-top: 56px;
    }

    .buttons {
      border-radius: 32px;
      background-color: #6c63ff;
      width: 730px;
      height: 224px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #f9f9f9;
      font-size: 36px;
      font-weight: 700;
      line-height: 120%;
      @media (max-width: 500px) {
        p {
          padding: 0 36px;
          margin-bottom: 24px;
        }
        width: 343px;
        font-size: 32px;
        height: auto;
        padding: 56px 0px;
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    .div-buttons {
      display: flex;
      gap: 16px;
      justify-content: center;
      margin-top: 24px;
      @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
    }
  }
  @media (max-width: 500px) {
    .container {
      padding: 0;
    }
    .img-container {
        display: flex;
        justify-content: center;
        height: 512px;
      }
  }
  .title-content {
    h1 {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      color: #2f382b;
      margin-bottom: 32px;
      max-width: 489px;
      @media (max-width: 850px) {
        width: 100%;
      }
    }
    p {
      font-family: "Rubik", sans-serif;
      /* font-family: Rubik; */
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: #9a9a9a;
      max-width: 580px;
      margin-bottom: 32px;
    }
    @media (max-width: 500px) {
      /* margin-top: 92px; */
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      margin-right: 1.5px;
      margin-left: 1.5px;
      h1 {
        font-size: 34px;
        width: 100%;
        text-align: left;
      }
      p {
        text-align: left;
      }
      img {
        margin-bottom: 0px;
        width: 32px;
      }
      .img-container {
        display: flex;
        justify-content: center;
        height: 512px;
      }
    }
  }

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    height: auto;
    align-items: flex-start;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`;

export const ctaSectionConheca = css`
  background: url(${BgSection3}) no-repeat center center / cover;
  padding: 70px 0;
  text-align: center;
  h2 {
    font-size: 28px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 33.6px */
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 32px;
    width: 100%;
    max-width: 730px;
    margin: auto;
    font-weight: 600;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-variation-settings: "wght" 400;
    line-height: 150%;
    color: #f9f9f9;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #ff7843;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const ctaSectionFacaParte = css`
  background: url(${BgSection9}) no-repeat center center / cover;
  padding: 160px 0;
  text-align: center;
  h2 {
    font-size: 28px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 33.6px */
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 32px;
    margin-bottom: 16px;
    span {
      color: #2f382b;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #f9f9f9;
    width: 100%;
    max-width: 610px;
    margin: auto;
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #ff7843;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const ctaSectionAgende = css`
  background: url(${BgSection11}) no-repeat center center / cover;
  padding: 90px 0;
  text-align: center;
  h2 {
    font-size: 28px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 33.6px */
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 32px;
    width: 100%;
    max-width: 730px;
    margin: auto;
    margin-bottom: 48px;
    span {
      color: #2f382b;
      font-variation-settings: "wght" 800;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #f9f9f9;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const carreiraMilitarSection = css`
  background: url(${BgSection2}) no-repeat center center / cover;
  padding: 90px 0;
  h2 {
    font-size: 36px;
    font-style: normal;
    line-height: 120%;
    font-variation-settings: "wght" 600;
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 32px;
    width: 100%;
    max-width: 500px;
    span {
      color: #2f382b;
      font-variation-settings: "wght" 800;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #ff7843;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
    :nth-child(n) {
      margin-bottom: 40px;
    }
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #f9f9f9;
    width: 100%;
    max-width: 610px;
    margin: auto;
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #f9f9f9;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const footerSection = css`
  background: #232323;
  padding: 90px 0;
  h2 {
    font-size: 28px;
    font-style: normal;
    line-height: 120%;
    font-variation-settings: "wght" 600;
    text-transform: uppercase;
    color: #f9f9f9;
    width: 100%;
    span {
      color: #ff7843;
      font-variation-settings: "wght" 800;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h3 {
    font-size: 16px;
    font-style: normal;
    line-height: 120%;
    font-variation-settings: "wght" 600;
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 8px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #f9f9f9;
  }
  a {
    img {
      width: 24px;
    }
  }
`;

export const conhecaOFundadorSection = css`
  background: url(${BgSection6}) no-repeat center center / cover;
  padding: 120px 0;
  h2 {
    font-size: 48px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 100%; /* 33.6px */
    text-transform: uppercase;
    color: #2f382b;
    margin-bottom: 16px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h3 {
    font-size: 36px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 150%; /* 33.6px */
    text-transform: uppercase;
    color: #2f382b;
    margin-bottom: 16px;
    ::before {
      border: 1px solid #ff7843;
      width: 10px;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h4 {
    font-size: 14px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%;
    text-transform: uppercase;
    color: #2f382b;
    margin-bottom: 40px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-variation-settings: "wght" 400;
    line-height: 150%;
    color: #9a9a9a;
    margin-bottom: 32px;
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #ff7843;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const teamSix = css`
  background: url(${BgSection2}) no-repeat center center / cover;
  padding: 146px 0;
  h2 {
    font-size: 76px;
    font-style: normal;
    line-height: 100%;
    font-variation-settings: "wght" 600;
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 24px;
    text-align: center;
    span {
      color: #2f382b;
      font-variation-settings: "wght" 800;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h5 {
    font-size: 28px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 33.6px */
    text-transform: uppercase;
    color: #f9f9f9;
    width: 100%;
    max-width: 400px;
    margin: auto;
    text-align: center;
  }
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #ff7843;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
    :nth-child(n) {
      margin-bottom: 40px;
    }
  }
  p {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #f9f9f9;
    width: 100%;
    max-width: 610px;
    margin: auto;
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #f9f9f9;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const content = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   > div {
      padding: 80px 0px 80px 120px;
      @media (max-width: 850px) {
     padding: 48px 0 0 0;
    }
  }
`;

export const aprovados = css`
  display: flex;
  justify-content: space-between;
  max-width: 540px;

  div {
    h3 {
      font-family: "geom-graphic", sans-serif;
      font-size: 48px;
      font-weight: 600;
      color: #ffffff;
      position: relative;
      z-index: 1;
      margin: 0;
    }

    p {
      display: block;
      position: relative;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #ffffff;
      line-height: 6px;
      margin-bottom: 32px;
      &:before {
        display: block;
        background-color: #ff7843;
        width: 100%;
        height: 10px;
        content: "";
        position: absolute;
        top: -18px;
        border-radius: 2px;
      }
    }
  }
`;

export const icons = css`
  div {
    div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 32px;
      max-width: 690px;
      &:last-of-type {
        padding-bottom: 0;
      }
      img {
        width: auto;
        margin-right: 8px;
      }
      span {
        font-size: 14px;
        line-height: 16.8px;
        font-weight: 600;
      }
    }
  }
`;
export const iconsBg = css`
  background-image: url(${BgIcons});
  height: 100%;
  width: 100%;
  background-repeat: repeat-y;
  background-size: cover;
`;

export const desktop = css`
  @media (max-width: 768px) {
    display: none !important;
  }
`;
export const mobile = css`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

