/** @jsx jsx */
import { jsx } from "@emotion/react";
import Select from 'react-select'
import { formSectionStyles, input, reverse, form } from "../assets/styles/FormSection.styles";
import { Col, Container, Row } from "react-bootstrap";
import illustrationForm from "../assets/images/LP-teamsix/imgform.png";
import { commonBtn2 } from "../assets/styles/layout.styles";

const HowToWork = () => {

  const options = [
    { value: 'interesse', label: 'INTERESSE' },
    { value: 'esa', label: 'ESA' },
    { value: 'espcex', label: 'EsPCEx' },
    { value: 'afa', label: 'AFA' },
    { value: 'efomm', label: 'EFOMM' },
    { value: 'en', label: 'EM' },
    { value: 'ime', label: 'IME' },
    { value: 'ita', label: 'ITA' },
    { value: 'outro', label: 'OUTRO' },
  ]

  return (
    <section id="matriculas" css={formSectionStyles}>
      <Container>
        <Row css={reverse}>
          <Col className="col-img">
            <img
              src={illustrationForm}
              data-aos="fade-right"
              alt="Soldado atirador"
            />
          </Col>
          <Col data-aos="fade-left" data-aos-delay="500">
            <Container className="form-container">
              <div id="formulario" className="form-div">
                 <h1>DÊ O PRIMEIRO<br/> PASSO NA SUA<br/> CARREIRA MILITAR</h1>
                <p>Garanta sua vaga entre os melhores agora!</p>
                <form
                  name='agendar-visita'
                  method='POST'
                  action='/success'
                  data-netlify='true'
                   css={form}
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='agendar-visita'
                  />
                 <div css={input}>
                    <input
                      type="text"
                      name="nome"
                      placeholder="Nome"
                      required
                    />
                  </div>
                  <div css={input}>
                    <input
                      type="mail"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div css={input}>
                    <input
                      type="tel"
                      name="telefone"
                      placeholder="Telefone"
                      required
                    />
                  </div>

                  <select name="select">
                    <option>INTERESSE</option>
                    <option value="esa">ESA</option>
                    <option value="espcex">EsPCEx</option>
                    <option value="afa">AFA</option>
                    <option value="efomm">EFOMM</option>
                    <option value="en">EM</option>
                    <option value="ime">IME</option>
                    <option value="ita">ITA</option>
                    <option value="outro">OUTRO</option>
                  </select>
                 
                  <button type='submit'>
                    Enviar
                  </button>
                </form>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HowToWork;
