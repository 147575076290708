/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "../styles/layout.styles";

export const mainSlider = css`
  margin-top: 57px;
  background-color: ${colors.primary};

  .swiper-slide {
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2F382B;
  }

  .image-layer {

    width: 100%;
    
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; */
    /* -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 7000ms ease;
    transition: -webkit-transform 7000ms ease;
    transition: transform 7000ms ease;
    transition: transform 7000ms ease, -webkit-transform 7000ms ease; */
  }

  .swiper-pagination {
      bottom: 20px;
    }

  .swiper-pagination-bullet {
    background: #ffffff;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: #FF7843;
    }
  }

  .swiper-slide-active {
    /* .image-layer {
      -webkit-transform: scale(1.15);
      transform: scale(1.15);
    } */

    & h3,
    p,
    a {
      visibility: visible;
      opacity: 1;
      -webkit-transform: translateY(0) translateX(0);
      transform: translateY(0) translateX(0);
    }
  }

  & [class*="swiper-button-"]::after {
    display: none;
  }

  & [class*="swiper-button-"] {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.35);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-size: 24px;
    transition: all 500ms ease;
    display: none;
    &:hover {
      background-color: #fff;
    }
    @media (min-width: 768px) {
      width: 70px;
      height: 70px;
      display: flex;
    }
  }

  .container {
    position: absolute;
  }
  & h3 {
    white-space: pre-line;
    font-size: 56px;
    color: #fff;
    line-height: 1.15em;
    font-weight: 300;
    margin: 0;
    margin-bottom: 16px;
    margin-top: 5px;
    text-transform: uppercase;

    @media not all and (min-resolution:.001dpcm) { 
     @supports (-webkit-appearance:none) {
          line-height: 28px;
        }
    }

    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 32px;
      margin-bottom: 10px;
    }

    span {
      color: ${colors.secondary};
      font-weight: 600;
      font-size: 56px;
      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 46px;
        line-height: 50px;
      }
    }
  }

  & p {
    font-size: 18px;
    color: #fff;
    margin: 0;
    margin-bottom: 30px;
    line-height: 27px;
    font-family: "Rubik", sans-serif;
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  & a {
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .swiper-slide {
      height: auto;
      padding-bottom: 0;
      align-items: flex-end;
      .container{
        bottom: 80px;
      }
    }
    .row {
      flex-direction: column-reverse;
    }
    .swiper-pagination {
      /* top: 60%;
      bottom: inherit !important; */
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .swiper-slide {
      height: auto;
      padding-bottom: 20px;
    }
    .row {
      flex-direction: column-reverse;
    }
    .swiper-pagination {
      /* top: 60%;
      bottom: inherit !important; */
    }
  }

  .swiper-button-next,
  .swiper-button-prev  {
      width: 16px;
      height:16px;
      border-radius:50%;
      background-color: #FF7843;
      &:hover{
        background-color: #ffffff;
      }
    }
   

  @media (min-width: 768px) {
    .swiper-button-next {
      right: 30px;
    }
    .swiper-button-prev {
      left: 30px;
    }
  }

  @media (min-width: 1200px) {
    .swiper-button-next {
      right: 60px;
    }
    .swiper-button-prev {
      left: 60px;
    }
  }
`;

export const sliderBtn = css`

`;

export const shapeImg = css`
  position: absolute;
  top: -25px;
  left: -42px;
`;

export const infoSlider = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;

  /* h3{
    text-transform: uppercase;
    font-weight: 300;
    span{
        font-weight: 600;
    }
  } */

  @media (min-width: 320px) and (max-width: 767px) {
    /* margin-top: 50px; */
    h3,
    a {
      /* margin: 0 auto 10px; */
    }
    p {
      /* text-align: center; */
      /* margin: 0 auto 30px; */
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    /* margin-top: 50px; */
    h3,
    a {
      /* margin: 0 auto 10px; */
    }
    p {
      /* text-align: center;
      margin: 0 auto 30px; */
    }
  }
`;

export const imageSlider = css`
  img {
    width: 85%;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    img {
      width: 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    img {
      width: auto;
      max-width: 100%;
      margin: 0 auto;
    display: block;
    }
  }
`;

export const desktop = css`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const mobile = css`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;