/** @jsx jsx */
import { jsx } from '@emotion/react'

import { ctaSectionAgende } from '../assets/styles/AboutTwo.styles'
import { commonBtn2 } from "../assets/styles/layout.styles";

const CtaContent = () => {
  return (
    <section id="contato" css={ctaSectionAgende}>
      <div>
        <h2 data-aos="fade-in" data-aos-delay="250">Agende sua Prova de Bolsa e concorra a até <span>100% de desconto nas mensalidades</span></h2>
        <a css={commonBtn2} data-aos="fade-in" data-aos-delay="500" href="https://web.whatsapp.com/send?phone=5551991709380&text=" target="_blank">FALAR COM O TEAM SIX</a>
      </div>
    </section>
  )
}

export default CtaContent
