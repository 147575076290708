import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import EficienciaElite from "@/components/EficienciaEliteContent";
import PrimeiroPasso from "@/components/PrimeiroPasso";
import CTAFaleConosco from "@/components/CTAFaleConosco";
import CTAFacaParte from "@/components/CTAFacaParte";
import PorqueIngressar from "@/components/CarreiraMilitar";
import TeamSixLegado from "@/components/TeamSixLegadoContent";
import CTAAgende from "@/components/CTAAgende";
import HeaderOne from "@/components/header-one";
import ConhecaOFundador from "@/components/ConhecaOFundador";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import FormSection from "@/components/formSection";
import Testimony from '@/components/testimony';
import SliderOne from "@/components/slider-one";

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Team Six">
          <HeaderOne />
          <SliderOne />
          <EficienciaElite />
          <CTAFaleConosco />
          <PrimeiroPasso />
          <TeamSixLegado />
          <ConhecaOFundador />
          <FormSection />
          <Testimony/>
          <CTAFacaParte />
          <PorqueIngressar />
          <CTAAgende />
          {/* <Routine />
          <InfoSection /> */}
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
